<template>
<NavBar @editStart="StartEdit()"/>

<h1 style="text-align: center;">תקנון האתר</h1>

<p class="termsParagrafh">{{ this.Terms }}</p>

<div class="empty">

</div>


<Footer/>

</template>

<script>
import NavBar from '@/components/NavBarcopy.vue'
import Footer from '@/components/Footer.vue'
// import EditTool from '@/components/EditTool.vue'
// import emailjs from '@emailjs/browser';


import axios from 'axios'


export default {
  name: 'Terms',
  components: {
    NavBar,
    Footer,
    // EditTool
},
  data(){
      return{
        Terms:
        `
       גולש יקר, השימוש באתרsnt-law.co.il  (להלן: "האתר") ובתכנים המוצגים בו כפוף לתנאי השימוש כפי שמפורטים להלן:
בטרם השימוש באתר, עליך לקרוא את התקנון. השימוש באתר מעיד כי הינך מסכים ומקבל את הכתוב בתקנון.
1. התכנים באתר מנוהלים ע"י עו"ד שרון נתח תמרי.
2. השימוש באתר, בתכנים ובמידע שבו הינו באחריותו הבלעדית של הגולש ו/או המשתמש                     למפעילי האתר לא תהיה כל אחריות מכל סוג שהוא כלפי משתמש כזה או אחר בגין כך.
3. מפעילי האתר ו/או כל אדם מטעמם לא יישאו בכל אחריות ביחס לתכנים המופיעים באתר הן בקשר לאמינותם ורמת הדיוק שלהם. 
4. מפעילי האתר לא יישאו בשום נזק או אחריות לנזק ישיר או עקיף שיגרם למשתמשים מהשימוש באתר או במידע שמופיע בו.
5. השימוש באתר אינו מקיים יחסי עורך דין – לקוח.
6. המידע המופיע באתר אינו מחליף ייעוץ משפטי. אין בשימוש באתר להוות הצעה וקיבול של מתן ייעוץ משפטי.
7. השימוש באתר ושימוש בתכנים המופיעים בו הינו באחריותו הבלעדית של הגולש.
8. מפעילי האתר עושים ככל שביכולתם לספק מידע מדויק, שלם ומעודכן אך אינם מתחייבים שהמידע הוא אכן כזה. 
9. המידע באתר לרבות בקישורי האינטרנט המפורסמים בו אינם מהווים תחליף לייעוץ משפטי. המשתמש לוקח על עצמו ועל אחריותו הבלעדית את הסיכונים הכרוכים בשימוש בקישורים המצויים באתר.
10. בכל בעיה בקישורים המופיעים באתר יש לפנות ישירות לנותני השירות.
11. על המשתמש לבדוק את אמיתות תוכן המידע המופיע באתר ואינו יכול להסתמך עליו או להעבירו לצדדים שלישיים.
12. אין המידע באתר, לרבות קישורי האינטרנט המפורסמים בו ולרבות תכנים ו/או מידע אשר נמצא בבעלות צד שלישי, מהווים תחליף למקורות ו/או לייעוץ משפטי על-ידי משפטן מוסמך.

13. האתר ומפעיליו אינם אחראים על טעויות, השמטות, אי-דיוקים או מידע מוטעה המצוי בבעלות ו/או מנוהל ו/או מופעל על ידי צד שלישי, בין אם פורסם באתר באופן ישיר ובין אם פורסם בדרך של הפנייה ו/או קישור אינטרנט ו/או בכל דרך אחרת, והמשתמש מקבל על עצמו, את מלוא הסיכון והאחריות בגין שימושו בתכני האתר.

14. במידה ותוכן באתר פוגע בזכויות קנייניות ו/או זכויות יוצרים ו/או כל זכות אחרת של אדם או גוף, לרבות מידע הפוגע בפרטיותו של אדם, ובכלל זה של המשתמש, יש לפנות אל נציגי האתר. כל פנייה תיבדק לגופה ולבעלי ו/או למפעילי האתר ו/או למי מטעמם מסור שיקול הדעת הבלעדי באשר לטיפול בפנייה זו.
15. המשתמש מצהיר כי הוא מוותר על כל טענה ו/או תביעה ו/או דרישה כנגד מפעילי האתר ו/או עובדיהם ו/או מי מטעמם בכל הנוגע לשימוש ע"י אותו גולש בתוכן ו/או במידע הקיים ו/או שיהיה באתר בעתיד.
16. ידוע למשתמש כי התכנים ו/או המידע שבאתר הינם כלליים וחלקיים בלבד ויתכן כי נפלו בהם טעויות ו/או שגיאות כאלה או אחרות ונכונות. 
17. ידוע למשתמש שהמידע והתכנים שבו עלולים ויכולים להשתנות מעת לעת עפ"י הצורך.
18. למפעיל האתר ולעובדיו הזכות הבלעדית לערוך שינויים באתר. ביכולתם, להוסיף או להסיר מידע, תכנים ותמונות מהאתר לפי שיקול דעתו הבלעדי והמוחלט.
19. כל זכויות היוצרים ו/או הקניין הרוחני באתר- לרבות בשם http://www.snt-law.co.il, בעיצוב האתר, בתכנים המתפרסמים בו ובכל תוכנה, טקסט, קוד מחשב, יישום וכל חומר אחר הכלולים בהם – הינם של מפעילי האתר או של כותבי התכנים בלבד, והכל לפי העניין.
20. מובהר בזאת כי אין לשנות, לשכתב, לתקן, למחוק, להוסיף, להעתיק, לצלם, לשכפל, לפרסם, להציג בפומבי, להפיץ, למסור לצד שלישי או לעשות כל שימוש אחר, מסחרי או לא מסחרי אחר, בכל חלק מן הנ"ל בלא קבלת הסכמתם המפורשת של בעלי הזכויות בתכנים ו/או בקבצים ו/או ביישומים ו/או בטקסטים, הכל לפי העניין בכתב ומראש.
21. מפעילי האתר אינם מחויבים לענות או להתייחס לפניות שנשלחות באתר.
22. על תקנון זה ועל האתר יחולו דיני מדינת ישראל.
23. כל שאלה משפטית תתברר בערכאה השיפוטית המוסמכת במחוז חיפה.
 
שימוש מהנה snt-law.co.il
התקנון מנוסח בלשון זכר אך מתייחס לשני המינים.

        `
      }
      
  },
  created(){
  },
  mounted(){
  },
  methods: {
    
  } 
}
</script>

<style scoped>

*{
  margin: 0;
  padding: 0;
}

/* -------------------- empty ------------------- */
.empty{
  height: 100px;
}

.backgroundBlog{
  border: 1px solid none;
  background-image: url('@/assets/photo/BlogBackground.jpg');
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.TitleDiv{
  border: 1px solid none;
  width: 100%;
  height: auto;
  text-align: center;
  background: #D4AF37;
background: linear-gradient(to bottom, #D4AF37 0%, #C5A028 100%);
background: -webkit-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
background: -moz-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
background: -o-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
background: -ms-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
border: 1px solid #B69119;
box-shadow: inset 0 1px 0 #E3BE46;
-webkit-box-shadow: inset 0 1px 0 #E3BE46;
-moz-box-shadow: inset 0 1px 0 #E3BE46;
  font-size: 2.2vw;
  padding: 15px;
}

.TitleDiv h1{
  text-align: center;
  color: #053c67;
}

.VideoContainer{
  border: 1px solid none;
  display: flex;
  position: relative;
  right: 50%;
  transform: translate(50%);
  width: 90%;
  height: auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

p.termsParagrafh{
  white-space: pre-line; 
  text-align: right;
  margin-top: 50px;
  border: 1px solid none;
  position: relative;
  width: fit-content;
  padding: 30px;
  right: 50%;
  font-size: 1.2vw;
  transform: translate(50%);
}

@media (max-width: 1200px) {
    p.termsParagrafh{
    font-size: 2vw;
  }
}

@media (max-width: 700px) {

  p.termsParagrafh{
    padding: 25px !important;
    font-size: 2.5vw;
  }
}


</style>




